import { Link } from "gatsby";
import React, { CSSProperties } from "react";

const footerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  fontSize: "smaller",
};

const contentLeftStyle: CSSProperties = {
  flex: "1",
};

const contentRightStyle: CSSProperties = {
  flex: "none",
};

export default function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={contentLeftStyle}>Copyright {new Date().getFullYear()}</div>
      <div style={contentRightStyle}>
        <Link to="/kontakt">Kontakt</Link>
        <Link to="/datenschutzerklaerung">Datenschutz</Link>
        <Link to="/impressum">Impressum</Link>
      </div>
    </footer>
  );
}
