// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-companies-tsx": () => import("./../../../src/pages/admin/companies.tsx" /* webpackChunkName: "component---src-pages-admin-companies-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-filecontent-file-id-beleg-beleg-nr-tsx": () => import("./../../../src/pages/filecontent/[fileId]/beleg/[belegNr].tsx" /* webpackChunkName: "component---src-pages-filecontent-file-id-beleg-beleg-nr-tsx" */),
  "component---src-pages-filecontent-file-id-index-tsx": () => import("./../../../src/pages/filecontent/[fileId]/index.tsx" /* webpackChunkName: "component---src-pages-filecontent-file-id-index-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interaktiv-tsx": () => import("./../../../src/pages/interaktiv.tsx" /* webpackChunkName: "component---src-pages-interaktiv-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-upload-tsx": () => import("./../../../src/pages/upload.tsx" /* webpackChunkName: "component---src-pages-upload-tsx" */)
}

