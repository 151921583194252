import React, { useContext } from "react";
import { Context } from "../store";
import Notification from "./Notification";

const Notifications = () => {
  const [context] = useContext(Context);

  return (
    <div>
      {/* { state.errors ? state.errors.map(error => {
                    return(<div className="notification notification-error">{error.text}</div>)
                }) : null
            } */}
      {context.error ? (
        <Notification type="error" notificationID={context.error.id}>
          {context.error.text}
        </Notification>
      ) : null}
      {context.success ? (
        <Notification type="success" notificationID={context.success.id}>
          {context.success.text}
        </Notification>
      ) : null}
    </div>
  );
};

export default Notifications;
