import React from "react";
import Store from "../store";
import CookieConsent from "react-cookie-consent";

import "./layout.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Notifications from "./Notifications";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";

type LayoutPropType = {
  title?: string;
  children: any;
};

export default function Layout({ title, children }: LayoutPropType) {
  return (
    <Store>
      {/* <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
                integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
                crossorigin="anonymous"
            /> */}
      <div className={"page"}>
        <title>rap-tool.de</title>
        <Header />
        <Navigation />
        <main>
          <Notifications />
          {title ? <h2>{title}</h2> : null}
          {children}
        </main>
        <Footer />
        <CookieConsent buttonText="Einverstanden">
          Diese Webseite benutzt Cookie um Funktionalitäten zu ermöglichen und
          das Nutzererlebnis zu verbessern. Es werden keine Cookies von
          Drittanbietern oder Werbetreibenden gesetzt.
        </CookieConsent>
      </div>
    </Store>
  );
}
