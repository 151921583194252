import { Link } from "@reach/router";
import React from "react";
import UserStatus from "../user-management/UserStatus";

export default function Header() {
  return (
    <header>
      <div className="mt-0 mb-3 inline-block">
        <div>
          <Link to="/">
            <img src="/images/icon.svg" alt="icon rap-tool.de" className="inline-block h-10" />
            <h1 className="inline-block align-middle ml-2">rap-tool.de</h1>
          </Link>
        </div>
        <span className="mt-1 hidden sm:block">Das Tool für Ihre Rechnungsabgrenzungen</span>
      </div>
      <UserStatus />
    </header>
  );
}
