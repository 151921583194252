import React, { createContext, useState } from "react";

type contextType = {
  mandant?: MandantType;
  error?: {
    text: string;
    id: string;
  };
  success?: {
    text: string;
    id: string;
  };
  file_id?: string;
  buchungen_month_filter?: string;
  buchungen?: Array<BuchungType>;
  belege?: Array<BelegType>;
};

const initialState = {};

type StoreProps = {
  children: any;
};

const Store = ({ children }: StoreProps) => {
  const [state, setState] = useState(initialState);
  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
};

export const Context = createContext<
  [contextType, React.Dispatch<React.SetStateAction<contextType>>]
>(initialState as any);
export default Store;
