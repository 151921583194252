import SuccessIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import React, { Children, useContext, useState } from "react";
import { removeNotification } from "../../utils/errors";
import { Context } from "../store";

type NotificationProp = {
  type?: "error" | "success" | "info";
  notificationID?: string;
  children: any;
};

export default function Notification({
  type,
  notificationID,
  children,
}: NotificationProp) {
  const [isHidden, setHidden] = useState(false);
  const [context, setContext] = useContext(Context);

  const onCloseButtonClick = () => {
    if (notificationID) {
      removeNotification(setContext, notificationID);
    }
    setHidden(true);
  };

  // Extra class for the type of notification
  const notificationTypeClass =
    type && ["error", "success"].includes(type) ? "notification-" + type : "";

  return (
    <div
      className={
        "flex notification flex-row " +
        (isHidden ? "hidden" : "") +
        notificationTypeClass
      }
    >
      {type == "info" ? (
        <InfoIcon className="flex-0 align-middle mr-2" />
      ) : type == "error" ? (
        <ErrorIcon className="flex-0 align-middle mr-2" />
      ) : type == "success" ? (
        <SuccessIcon className="flex-0 align-middle mr-2" />
      ) : null}
      {/* <InfoIcon className="flex-0 align-middle mr-2" /> */}
      <div className="inline-block flex-1 w-full align-middle">
        <div className="inline-block align-middle">{children}</div>
      </div>
      <div className="inline-block flex-0">
        <button
          onClick={onCloseButtonClick}
          className="button-none button-inline button"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}
