import { Link } from "gatsby";
import React from "react";
import { authenticationService } from "../../_services/authenticationService";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const UserStatus = () => {
  let user = authenticationService.currentUser();

  let displayname = "";
  let role = undefined; // Can either be the company or "admin"
  if (user) {
    displayname = user.name ? `${user.name} ${user.surname}` : user.username;
    if (user.isAdmin) {
      role = "admin";
    } else if (user.companyName) {
      role = user.companyName;
    }
  }

  return (
    <div style={{ float: "right", height: "40px" }}>
      {user ? (
        <Link to="/profile">
          <div
            style={{
              textAlign: "center",
              display: "inline-block",
              marginRight: "10px",
            }}
          >
            {displayname}
            <br />
            {role ? <span style={{ fontSize: "small" }}>({role})</span> : null}
          </div>
        </Link>
      ) : null}
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          height: "100%",
        }}
      >
        {user ? (
          <Link to="/logout">
            <ExitToAppIcon />
          </Link>
        ) : (
          <Link to="/login">login</Link>
        )}
      </div>
    </div>
  );
};

export default UserStatus;
