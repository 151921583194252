export interface LocalStorageUser {
  username: String;
}

export const isBrowser = () => typeof window !== "undefined";

export const setLocalStorageItem = (key: string, value: string) => {
  if (isBrowser()) {
    window.localStorage.setItem(key, value);
  }
};

export const getLocalStorageItem = (key: string) => {
  if (isBrowser()) {
    return window.localStorage.getItem(key);
  }
  return null;
};

export const removeLocalStorageItem = (key: string) => {
  if (isBrowser()) {
    return window.localStorage.removeItem(key);
  }
  return null;
};
