import { v4 as uuidv4 } from "uuid";

const ERROR_DISPLAY_DURATION = 5000; // Dauer in Milisekunden

export const addSuccess = (setState: any, text: string) => {
  const id = uuidv4();
  const success = {
    id: id,
    text: text,
  };

  setState({ success });

  // Remove after 3 seconds
  setTimeout(() => {
    removeSuccess(setState, id);
  }, ERROR_DISPLAY_DURATION);
};

export const addError = (setContext: any, text: string) => {
  const id = uuidv4();
  const error = {
    id: id,
    text: text,
  };

  setContext({ error });

  // // Remove the error after 3 seconds
  // setTimeout(() => {
  //   removeError(setContext, id);
  // }, ERROR_DISPLAY_DURATION);
};

const removeError = (setState: any, id: string) => {
  setState({ error: null });
};

const removeSuccess = (setState: any, id: string) => {
  setState({ success: null });
};

export const removeNotification = (setState: any, id: string) => {
  removeSuccess(setState, id);
  removeError(setState, id);
};
