import { urlService } from ".";
import { getLocalStorageItem, LocalStorageUser, removeLocalStorageItem, setLocalStorageItem } from "./localStorageService";

const CURRENT_USER_LOCAL_STORAGE_KEY = "currentUser";

export const authenticationService = {
  login,
  refresh,
  logout,
  isLoggedIn,
  currentUser,
  changePassword,
  getJWTValidityInSeconds,
};

async function login(username: string, password: string) {
  let url = urlService.getUrl("/user/login");

  const response = await urlService
    .getAxios()
    .post(
      url,
      {
        user: username,
        password: password,
      },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch((err) => {
      if (err.response && err.response.status == 401) {
        throw new Error("Login fehlgeschlagen. Die Kombination aus Email und Passwort ist nicht korrekt.");
      }
      if (err.response && err.response.data && err.response.data.displayMessage) {
        throw new Error(err.response.data.displayMessage);
      }
      if (err.response && err.response.status == 504) {
        throw new Error("Der Server konnte nicht erreicht werden. Bitte prüfen Sie Ihre Internetverbindung.");
      }
      throw new Error("Unbekannter Fehler");
    });

  if (!response || response.status >= 400) {
    throw new Error("Unbekannter Fehler");
  }

  setLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(response.data));
  // localStorage.setItem('currentUser', JSON.stringify(response.data))
}

async function logout() {
  // remove user from local storage to log user out locally
  removeLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);

  let url = urlService.getUrl("/user/logout");

  let response = await urlService
    .getAxios()
    .post(
      url,
      {
        // We don't send any content as it is not expected. The user is in the Cookie
      },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => {
      console.log(err);
    });

  return response;
}

async function refresh() {
  let url = urlService.getUrl("/user/refresh-token");
  let response = await urlService
    .getAxios()
    .get(url, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
      withCredentials: true, // This is necessary so that the response cookie is set
    })
    .catch((err) => {
      console.log(err);
    });

  if (!response || response.status >= 400) {
    throw new Error("Call to Login api failed");
  }

  setLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(response.data));
}

function isLoggedIn() {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return false;
  }

  const user = JSON.parse(value);

  if (!user || !user.tokenExpires) {
    return false;
  }

  const tokenExpires = new Date(user.tokenExpires);
  return tokenExpires > new Date();
}

async function changePassword(old_password: string, new_password: string) {
  let url = urlService.getUrl("/user/change-password");
  let response = await urlService
    .getAxios()
    .post(
      url,
      { old_password, new_password },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch((err) => {
      console.log(err);
    });

  if (!response || response.status !== 200) {
    throw new Error("Call to Login api failed");
  }

  return true;
}

function getJWTValidityInSeconds() {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return 0;
  }
  const user = JSON.parse(value);
  const tokenExpires = new Date(user.tokenExpires);
  const now = new Date();
  // getTime returns time in miliseconds
  return (tokenExpires.getTime() - now.getTime()) / 1000;
}

function currentUser() {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return null;
  }
  return isLoggedIn() ? JSON.parse(value) : null;
}
