import axios from "axios";

// function getUrl (raw_url) {
const getUrl = (raw_url: string) => {
  if (!raw_url.startsWith("/")) {
    raw_url = "/" + raw_url;
  }

  if (!raw_url.startsWith("/api")) {
    raw_url = "/api" + raw_url;
  }

  // if (process.env.GATSBY_API_URL) {
  //     raw_url = process.env.GATSBY_API_URL + raw_url
  // }

  return raw_url;
};

/**
 * Function to get a standardized version of axios. This allows to set credentials in a centralized way
 */
const getAxios = () => {
  return axios.create({
    withCredentials: true,
  });
};

export const urlService = {
  getUrl,
  getAxios,
};
